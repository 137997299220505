import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import Container from '~/components/Container'

import * as classes from './Gallery.module.scss'

const Gallery = ({ images }) => {
  if (!images) {
    return null
  }

  return (
    <Container>
      <div className={classes.gallery}>
        {images.map((image, index) => (
          <div key={index} className={classes.singleGallery}>
            <GatsbyImage
              image={image.image.gatsbyImageData}
              alt={image.image.alt || `gallery-${index}`}
            />
          </div>
        ))}
      </div>
    </Container>
  )
}

export default Gallery
