import React from 'react'
import { graphql } from 'gatsby'

import Layout from '~/components/Layout'
import Seo from '~/components/Seo'
import IntroSection from '~/components/IntroSection'
import Gallery from '~/components/Gallery/Gallery'

import * as classes from './About.module.scss'

const AboutPage = ({ data }) => {
  const aboutPage = data.prismicAbu.data

  const {
    seo_title: seoTitle,
    seo_description: seoDescription,
    images,
  } = aboutPage

  const introSectionProps = {
    title: aboutPage.intro_title.text,
    textRaw: aboutPage.intro_text.raw,
    link: {
      to: aboutPage.intro_link.slug,
      text: 'Get in touch',
    },
    gatsbyImageData: {
      image: aboutPage.intro_illustration.gatsbyImageData,
      alt: aboutPage.intro_illustration.alt,
    },
  }

  return (
    <Layout>
      <Seo title={seoTitle?.text} description={seoDescription?.text} />
      <div className={classes.aboutPageIntro}>
        <IntroSection {...introSectionProps} />
      </div>
      <div className={classes.aboutGallery}>
        <Gallery images={images} />
      </div>
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query about {
    prismicAbu {
      data {
        seo_description {
          text
        }
        seo_title {
          text
        }
        intro_illustration {
          gatsbyImageData(layout: FIXED)
          alt
        }
        intro_text {
          raw
        }
        intro_title {
          text
        }
        intro_link {
          slug
        }
        images {
          image {
            alt
            gatsbyImageData(layout: CONSTRAINED, sizes: "50vw")
          }
        }
      }
    }
  }
`
